.open {
    cursor: pointer;
    transition: 0.3s;
}

.close {
    transition: 0.3s;
    cursor: pointer;
}

.close span {
    transform: rotate(180deg);
    writing-mode: vertical-lr;
}
